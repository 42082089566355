import React from 'react';
import PropTypes from 'prop-types';
import { JssProvider } from 'react-jss';
import I18nProvider from './I18nProvider';
import QueuedModalProvider from './QueuedModalProvider';
import PathNamesProvider from './PathNames';
import FaqProvider from './FaqProvider';

const Providers = ({
  children,
  region,
  isCorporateAdmin,
  jss,
  generateId,
  sharedModalState,
  faqTarget,
  faqTitleID,
}) => (
  <I18nProvider region={region}>
    <PathNamesProvider region={region} isCorporateAdmin={isCorporateAdmin}>
      <JssProvider generateId={generateId} jss={jss}>
        <QueuedModalProvider state={sharedModalState}>
          <FaqProvider target={faqTarget} faqTitleID={faqTitleID}>
            {children}
          </FaqProvider>
        </QueuedModalProvider>
      </JssProvider>
    </PathNamesProvider>
  </I18nProvider>
);

Providers.propTypes = {
  isCorporateAdmin: PropTypes.bool,
  jss: PropTypes.object.isRequired,
  faqTarget: PropTypes.string,
  faqTitleID: PropTypes.string,
  region: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  generateId: PropTypes.func.isRequired,
  sharedModalState: PropTypes.object.isRequired,
};

export default Providers;
