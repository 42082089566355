import PropTypes from 'prop-types';
import React from 'react';
import bindMethods from '../utils/bindMethods';

// An instance of QueuedModalState can be passed to <QueuedModalProvider> to share the modal queue
// state between multiple React render trees.

// <QueuedModal>/<QueuedModalProvider> ensure that if multiple <QueuedModal> modal components are
// rendered at the same time, the modals will only be shown one at a time instead of
// stacking on top of each other.
export class QueuedModalState {
  constructor() {
    this.nextModalId = 0;
    this.modalQueue = [];
  }

  registerModal(onIsActive) {
    const modalId = this.nextModalId;
    this.nextModalId += 1;

    this.updateModalQueue(() =>
      [...this.modalQueue, { modalId, onIsActive }]
    );

    return modalId;
  }

  unregisterModal(modalIdToRemove) {
    this.updateModalQueue(modalQueue =>
      modalQueue.filter(({ modalId }) => modalId !== modalIdToRemove)
    );
  }

  updateModalQueue(updateFunction) {
    const currentActiveModal = this.modalQueue[0];

    this.modalQueue = updateFunction(this.modalQueue);

    const newActiveModal = this.modalQueue[0];

    if (newActiveModal && currentActiveModal !== newActiveModal) {
      // Notify the new active modal that it is active.
      newActiveModal.onIsActive();
    }
  }

  isActiveModal(modalId) {
    return this.modalQueue.length > 0 && this.modalQueue[0].modalId === modalId;
  }
}

export default class QueuedModalProvider extends React.Component {
  constructor(props) {
    super(props);

    // NOTE: The `state` prop is only used on mount, and cannot be changed afterward. Switching the
    // state object dynamically could lead to invalid state (and wouldn't be very useful anyway).
    const modalState = props.state || new QueuedModalState();

    this.provider = bindMethods(modalState, ['registerModal', 'unregisterModal', 'isActiveModal']);
  }

  getChildContext() {
    return this.provider;
  }

  render() {
    return this.props.children;
  }
}

QueuedModalProvider.propTypes = {
  children: PropTypes.node,
  state: PropTypes.instanceOf(QueuedModalState),
};

export const QueuedModalProviderShape = QueuedModalProvider.childContextTypes = {
  registerModal: PropTypes.func.isRequired,
  unregisterModal: PropTypes.func.isRequired,
  isActiveModal: PropTypes.func.isRequired,
};
