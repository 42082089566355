import Mnoga from '@engoo/mnoga';
// Import translation files.
import en from '../config/locales/en.json';
import es from '../config/locales/es.json';
import esMX from '../config/locales/es-MX.json';
import id from '../config/locales/id.json';
import it from '../config/locales/it.json';
import ja from '../config/locales/ja.json';
import ko from '../config/locales/ko.json';
import ptBR from '../config/locales/pt-BR.json';
import ru from '../config/locales/ru.json';
import th from '../config/locales/th.json';
import tr from '../config/locales/tr.json';
import vi from '../config/locales/vi.json';
import zhHant from '../config/locales/zh-Hant.json';
import zhHans from '../config/locales/zh-Hans.json';

/**
 * Extend Mnoga to include our phrases and fallbacks.
 */
export default class I18nService extends Mnoga {
  constructor() {
    super();

    // Initialize the translations.
    this.setPhrases('en', en);
    this.setPhrases('es', es);
    this.setPhrases('es-MX', esMX);
    this.setPhrases('id', id);
    this.setPhrases('it', it);
    this.setPhrases('ja', ja);
    this.setPhrases('ko', ko);
    this.setPhrases('pt-BR', ptBR);
    this.setPhrases('ru', ru);
    this.setPhrases('th', th);
    this.setPhrases('tr', tr);
    this.setPhrases('vi', vi);
    this.setPhrases('zh-Hant', zhHant);
    this.setPhrases('zh-Hans', zhHans);

    this.setFallback('en');
  }
}
