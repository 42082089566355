import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import FaqService from '../services/FaqService';

const FaqContext = createContext();
const FaqProvider = ({ faqTitleID, target, children }) => {
  const faq = new FaqService(target);
  return (
    <FaqContext.Provider value={{ faq, target, faqTitleID }}>
      {children}
    </FaqContext.Provider>
  );
};

FaqProvider.propTypes = {
  target: PropTypes.string,
  faqTitleID: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const useFaq = () => useContext(FaqContext);

export { FaqProvider as default, useFaq };

