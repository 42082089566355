import 'core-js/es/map';
import 'core-js/es/set';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import queryString from 'query-string';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useParams
} from 'react-router-dom';
import AppProvider from './utils/AppProvider';
import { PathNamesConsumer } from './utils/PathNames';

const {
  region,
  replace_id,
  member_data,
  target_market,
  banner_locale,
  was_corp_user,
  cut_off_period,
  lesson_fees,
  has_legacy_pricing,
} = window.app;

const achievementList = document.querySelector('.achievement-list');
if (achievementList) {
  import('./components/achievements/AchievementList')
    .then(({ default: AchievementList }) => {
      ReactDOM.render(
        <AppProvider>
          <AchievementList
            region={region}
            replaceId={replace_id}
            initialAchievementId={Number(document.location.hash.slice(1))}
          />
        </AppProvider>,
        achievementList
      );
    }
  );
}

const eikaiwaBanners = document.querySelectorAll('[data-banner]');
if (eikaiwaBanners) {
  import('./components/banner/BannerContent')
    .then(({ default: BannerContent }) => {
      eikaiwaBanners.forEach((bannerNode) => {
        ReactDOM.render(
          <AppProvider>
            <BannerContent
              position={bannerNode.dataset.banner}
              memberData={member_data}
              targetMarket={target_market}
              bannerLocale={banner_locale}
              wasCorpUser={was_corp_user}
            />
          </AppProvider>,
          bannerNode
        );
      });
    }
  );
}

const recentAchievementModal = document.querySelector('.recent-achievement-modal');
if (recentAchievementModal) {
  import('./components/achievements/RecentAchievementModal')
    .then(({ default: RecentAchievementModal }) => {
      ReactDOM.render(
        <AppProvider>
          <RecentAchievementModal region={region} replaceId={replace_id} />
        </AppProvider>,
        recentAchievementModal
      );
    }
  );
}

const headerMobileBar = document.querySelector('.header-mobile-bar');
if (headerMobileBar) {
  import('./components/header/HeaderMobileBar')
    .then(({ default: HeaderMobileBar }) => {
      ReactDOM.render(
        <AppProvider>
          <HeaderMobileBar
            region={region}
            replaceId={replace_id}
            target={headerMobileBar.dataset.target}
          />
        </AppProvider>,
        headerMobileBar
      );
    }
  );
}

const announcementListPage = document.querySelector('.announcement-list-page');
if (announcementListPage) {
  import('./components/announcements/AnnouncementListPage')
    .then(({ default: AnnouncementListPage }) => {
      const ListPage = () => {
        const { search } = useLocation();
        return (
          <AnnouncementListPage
            region={region}
            target={announcementListPage.dataset.target}
            unReadMark={announcementListPage.dataset.isAdmin !== 'true'}
            replaceId={replace_id}
            pageIndex={parseInt(queryString.parse(search).page || 1, 10)}
            cutOffPeriod={cut_off_period}
            lessonFees={lesson_fees}
            hasLegacyPricingFlag={has_legacy_pricing}
          />
        );
      };

      ReactDOM.render(
        <AppProvider>
          <PathNamesConsumer>
            {pathNames => (
              <Router>
                <Routes>
                  <Route
                    path={pathNames.announcements}
                    element={<ListPage />}
                  />
                </Routes>
              </Router>
            )}
          </PathNamesConsumer>
        </AppProvider>,
        announcementListPage
      );
    }
  );
}

const announcementDetailPage = document.querySelector('.announcement-detail-page');
if (announcementDetailPage) {
  import('./components/announcements/AnnouncementDetailPage')
    .then(({ default: AnnouncementDetailPage }) => {
      const DetailPage = () => {
        const { pageId } = useParams();
        return (
          <AnnouncementDetailPage
            region={region}
            replaceId={replace_id}
            target={announcementDetailPage.dataset.target}
            announcementId={parseInt(pageId, 10)}
            cutOffPeriod={cut_off_period}
            lessonFees={lesson_fees}
            hasLegacyPricingFlag={has_legacy_pricing}
          />
        );
      };

      ReactDOM.render(
        <AppProvider>
          <PathNamesConsumer>
            {pathNames => (
              <Router>
                <Routes>
                  <Route
                    path={`${pathNames.announcementsDetail}/:pageId`}
                    element={<DetailPage />}
                  />
                </Routes>
              </Router>
            )}
          </PathNamesConsumer>
        </AppProvider>,
        announcementDetailPage
      );
    }
  );
}

// Those are useing only admin.eikaiwa.dmm.com
// so no need to import for eikaiwa front. That's why this uses dynamic import/code splitting.
const adminIndex = document.querySelector('.admin-index');
if (adminIndex) {
  import('./components/admin/AdminIndex').then(({ default: AdminIndex }) => {
    ReactDOM.render(
      <AppProvider>
        <AdminIndex
          target={adminIndex.dataset.target}
          replaceId={replace_id}
          unReadMark={adminIndex.dataset.isAdmin !== 'true'}
          cutOffPeriod={cut_off_period}
          lessonFees={lesson_fees}
        />
      </AppProvider>,
      adminIndex
    );
  });
}

const textareaForm = document.querySelectorAll('.textarea-form');
if (textareaForm) {
  import('./components/admin/TextareaForm')
    .then(({ default: TextareaForm }) => {
      textareaForm.forEach((target) => {
        const { no_wrap, ...rest } = target.dataset;
        ReactDOM.render(
          <AppProvider>
            <TextareaForm
              textareaData={{ noWrap: no_wrap !== undefined, ...rest }}
              initialTextData={target.textContent}
            />
          </AppProvider>,
          target,
        );
      });
    });
}

const announcementPreview = document.querySelector('.announcement-preview');
if (announcementPreview) {
  import('./components/announcements/AnnouncementPreview').then(
    ({ default: AnnouncementPreview }) => {
      const PreviewPage = () => {
        const { pageId } = useParams();
        return (
          <AnnouncementPreview
            announcementId={parseInt(pageId, 10)}
          />
        );
      };
      ReactDOM.render(
        <AppProvider>
          <Router>
            <Routes>
              <Route
                path={'/announcements/detail/:pageId'}
                element={<PreviewPage />}
              />
            </Routes>
          </Router>
        </AppProvider>,
        announcementPreview
      );
    }
  );
}

const announcementDropdown = document.querySelector('.announcement-dropdown');
if (announcementDropdown) {
  import('./components/announcements/AnnouncementDropdown')
  .then(({ default: AnnouncementDropdown }) => {
    ReactDOM.render(
      <AppProvider>
        <AnnouncementDropdown
          region={region}
          replaceId={replace_id}
          target={announcementDropdown.dataset.target}
          layoutMode={announcementDropdown.dataset.layoutMode}
          cutOffPeriod={cut_off_period}
          lessonFees={lesson_fees}
          hasLegacyPricingFlag={has_legacy_pricing}
        />
      </AppProvider>,
      announcementDropdown
    );
  });
}

const importantAnnouncement = document.querySelector('.important-announcement');
if (importantAnnouncement) {
  import('./components/announcements/ImportantAnnouncement')
  .then(({ default: ImportantAnnouncement }) => {
    ReactDOM.render(
      <AppProvider>
        <ImportantAnnouncement
          region={region}
          replaceId={replace_id}
          target={importantAnnouncement.dataset.target}
          cutOffPeriod={cut_off_period}
          lessonFees={lesson_fees}
          hasLegacyPricingFlag={has_legacy_pricing}
        />
      </AppProvider>,
      importantAnnouncement
    );
  });
}

const blogPostList = document.querySelector('.blog-post-list');
if (blogPostList) {
  import('./components/blog/Blog').then(({ default: Blog }) => {
    const url = blogPostList.dataset.blogUrl;
    const perpage = blogPostList.dataset.perPage;
    const service = blogPostList.dataset.service;

    ReactDOM.render(
      <AppProvider>
        <Blog
          region={region}
          url={url}
          perpage={perpage}
          service={service}
        />
      </AppProvider>,
      blogPostList
    );
  });
}

const persistentAnnouncement = document.querySelector('.persistent-announcement');
if (persistentAnnouncement) {
  import('./components/announcements/PersistentAnnouncement')
  .then(({ default: PersistentAnnouncement }) => {
    ReactDOM.render(
      <AppProvider>
        <PathNamesConsumer>
          { pathNames => (
            <PersistentAnnouncement
              replaceId={replace_id}
              target={persistentAnnouncement.dataset.target}
              linkUrl={pathNames.announcementsDetail}
              cutOffPeriod={cut_off_period}
              lessonFees={lesson_fees}
            />)
          }
        </PathNamesConsumer>
      </AppProvider>,
      persistentAnnouncement,
    );
  });
}

const markUpContents = document.querySelectorAll('.markup-content');
if (markUpContents) {
  import('./utils/FormattedBlockText')
  .then(({ default: FormattedBlockText }) => {
    markUpContents.forEach((displayNode) => {
      ReactDOM.render(
        <FormattedBlockText
          text={displayNode.textContent}
          showError
        />,
        displayNode
      );
    });
  });
}

window._eikaiwa_parseContent = (content, target) => {
  import('./utils/FormattedBlockText')
  .then(({ default: FormattedBlockText }) => {
    ReactDOM.render(
      <FormattedBlockText
        text={content}
        showError
      />,
      target
    );
  });
};


const faqPage = document.querySelector('.faq-page');
if (faqPage) {
  import('./components/faq/Faq')
    .then(({ default: Faq }) => {
      ReactDOM.render(
        <AppProvider>
          <Faq />
        </AppProvider>,
        faqPage
      );
    });
}
