import React from 'react';
import PropTypes from 'prop-types';

const PathNames = React.createContext();

export const PathNamesConsumer = PathNames.Consumer;

class PathNamesProvider extends React.Component {
  constructor(props) {
    super(props);
    const { region, isCorporateAdmin } = this.props;
    const isFrontEnd = region === 'japan' && !isCorporateAdmin;

    this.pathName = {
      announcements: isFrontEnd ? '/announce' : '/announcements',
      announcementsDetail: isFrontEnd ? '/announce' : '/announcements/detail',
    };
  }

  render() {
    return (
      <PathNames.Provider value={this.pathName}>
        {this.props.children}
      </PathNames.Provider>
    );
  }
}

PathNamesProvider.propTypes = {
  region: PropTypes.string.isRequired,
  isCorporateAdmin: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default PathNamesProvider;
