/**
 * Takes an object and an Array of method names and returns a plain object mapping each method name
 * to a version of the method bound to the given object.
 *
 * Example usage:
 *
 * ```
 * class MyButton extends React.Component {
 *   constructor() {
 *     super();
 *
 *     this.state = {
 *       ...
 *     };
 *
 *     this.bound = bindMethods(this, ['onClick', 'onFocus']);
 *   }
 *
 *   onClick() {
 *     this.setState(...);
 *   }
 *
 *   onFocus() {
 *     this.setState(...);
 *   }
 *
 *   render() {
 *     return (
 *       <button onClick={this.bound.onClick} onFocus={this.bound.onFocus}>
 *         My button
 *       </button>
 *     );
 *   }
 * }
 * ```
 */
export default function bindMethods(object, methodNames) {
  const boundMethods = {};

  methodNames.forEach((name) => {
    boundMethods[name] = object[name].bind(object);
  });

  return boundMethods;
}
