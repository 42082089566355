export const ENGOO_DEFAULT = 'global';
export const SPAIN = 'spain';
export const MEXICO = 'mexico';
export const INDONESIA = 'indonesia';
export const ITALY = 'italy';
export const JAPAN = 'japan';
export const KOREA = 'korea';
export const BRAZIL = 'brazil';
export const RUSSIA = 'russia';
export const THAILAND = 'thailand';
export const TURKEY = 'turkey';
export const VIETNAM = 'vietnam';
export const TAIWAN = 'taiwan';
export const CHINA = 'china';

export const MARKET_LIST = [
  ENGOO_DEFAULT,
  SPAIN,
  MEXICO,
  INDONESIA,
  ITALY,
  JAPAN,
  KOREA,
  BRAZIL,
  RUSSIA,
  THAILAND,
  TURKEY,
  VIETNAM,
  TAIWAN,
  CHINA,
];

export const getLocale = {
  [ENGOO_DEFAULT]: 'en',
  [SPAIN]: 'es',
  [MEXICO]: 'es-us',
  [INDONESIA]: 'id',
  [ITALY]: 'it',
  [JAPAN]: 'ja',
  [KOREA]: 'ko',
  [BRAZIL]: 'pt-br',
  [RUSSIA]: 'ru',
  [THAILAND]: 'th',
  [TURKEY]: 'tr',
  [VIETNAM]: 'vi',
  [TAIWAN]: 'zh-hant',
  [CHINA]: 'zh-hans',
};
