const IMPORTANT = '!important';

function makeImportant(value) {
  const hasImportant =
    String(value).substr(value.length - IMPORTANT.length, IMPORTANT.length) === IMPORTANT;

  return hasImportant ? value : `${value} ${IMPORTANT}`;
}

/**
 * JSS plugin to automatically add `!important` to styles.
 *
 * Usage:
 *
 * Make all stylesheets add `!important` by default:
 *
 * ```jss.use(autoImportant({ importantByDefault: true }));```
 *
 * Make this stylesheet use `!important` by default:
 *
 * ```jss.createStyleSheet({ ... }, { important: true });```
 *
 * Make all styles for this class use `!important`:
 *
 * ```
 * {
 *   myClassName: {
 *     important: true,
 *     color: 'red',
 *   },
 * }
 * ```
 */
export default function autoImportant({
  importantByDefault = true,
} = {}) {
  const onProcessStyle = (style, sheet) => {
    const newStyle = style;
    const newSheet = sheet;

    let important = importantByDefault;

    if (newStyle && newStyle.hasOwnProperty('important')) {
      important = newStyle.important;

      delete newStyle.important;
    } else if (newSheet && newSheet.options && newSheet.options.important) {
      important = newSheet.options.important;
    }

    if (important) {
      Object.keys(newStyle).forEach(key => {
        const value = newStyle[key];
        if (typeof value === 'string' || typeof value === 'number') {
          newStyle[key] = makeImportant(value);
        }
      });
    }

    return newStyle;
  };

  return { onProcessStyle };
}
