import React from 'react';
import PropTypes from 'prop-types';
import preset from 'jss-preset-default';
import { create as createJss } from 'jss';
import { createGenerateId } from 'react-jss';
import { QueuedModalState } from './QueuedModalProvider';
import autoImportant from './jss-important';
import Providers from './Providers';
import { ENGOO_DEFAULT, MARKET_LIST } from '../constants/Countries';

// create own Jss instance, setup plugins and pass it to JssProvider.
// autoImportant is original plugin for adding `!important` to jss styles.
// because eikaiwa and engoo already using `!important`
// and also specified directly `a` `li` etc. in own css.
const globalJss = createJss(preset());
globalJss.use(autoImportant());

// if render multiple react rendering trees in one application, will get class name collisions.
// because every JssProvider rerender will reset the class names generator.
// To avoid this, share the class names generator between multiple JssProvider instances.
const generateId = createGenerateId();

const globalSharedModalState = new QueuedModalState();

const globalRegion = (window?.app?.region) || ENGOO_DEFAULT;
const isCorporateAdmin = window?.app?.is_corporate_admin;
const faqTarget = window?.app?.faq_target;
const faqTitleID = window?.app?.faq_title_id;

const AppProviders = ({ children, region = globalRegion }) => (
  <Providers
    region={region}
    isCorporateAdmin={isCorporateAdmin}
    jss={globalJss}
    generateId={generateId}
    sharedModalState={globalSharedModalState}
    faqTarget={faqTarget}
    faqTitleID={faqTitleID}
  >
    {children}
  </Providers>
);

AppProviders.propTypes = {
  children: PropTypes.node.isRequired,
  region: PropTypes.oneOf(MARKET_LIST),
};

export default AppProviders;
